import { StringTests, ResponseType, AlertType } from '../../../constants';
import RecaptchForm from '../../../components/mixins/RecaptchaForm';

export default {
  name: 'StartPasswordRecoveryForm',

  mixins: [RecaptchForm],

  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    routeEmail: {
      type: String,
      default: null,
    },
    store: {
      type: String,
      default: 'login-extended'
    },
  },
  data() {
    return {
      valid: true,
      email: this.routeEmail || null,
      emailRules: [
        (v) => !!v || this.$vuetify.lang.t('$vuetify.alerts.email_required'),
        (v) => StringTests.email.test(v) || this.$vuetify.lang.t('$vuetify.alerts.email_not_valid'),
      ],
      formSending: false,
      message: null,
      formSent: false,
    };
  },
  methods: {
    validateForm() {
      return this.$refs.startPasswordRecoveryForm.validate();
    },
    resetForm() {
      this.$refs.startPasswordRecoveryForm.reset();
    },
    async submit() {
      if (this.validateForm()) {
        this.formSending = true;
        const startPasswordRecoveryResult = await this.startPasswordRecovery();

        if (startPasswordRecoveryResult.type === ResponseType.SUCCESS) {
          this.resetForm();
          this.message = {
            type: AlertType.success,
            text: this.$vuetify.lang.t('$vuetify.startPasswordRecoveryForm.formSentText'),
          };
        } else {
          this.message = {
            type: AlertType.error,
            text: this.$vuetify.lang.t('$vuetify.startPasswordRecoveryForm.formSendingErrorText'),
          };
        }

        this.formSending = false;
        this.formSent = true;
        return true;
      }

      return false;
    },

    async startPasswordRecovery() {
      let payload = {
        email: this.email,
      };

      if (this.enableRecaptcha) {
        payload.rcToken = await this.recaptchaToken('start_reset_password');
      }

      const startPasswordRecoveryResult = await this.$store
        .dispatch(`${this.store}/startPasswordRecovery`, payload);

      return startPasswordRecoveryResult;
    },

    recoverAgain() {
      this.email = null;
      this.message = null;
      this.formSent = false;
    },
  },
};
